import React, { useState } from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { reportClaimsBanner } from "../utils/contactInformation";
import Banner from "../components/banner/banner";
import Button from '../components/button/button';
import '../styles/contact.scss';
//import cars from "../assets/car-bike.png"




// markup
const ReportClaims = () => {

    const [msgError, setMsgError] = useState(false);

    const [reportMessage, setReportMessage] = useState({
        pageType: 'report claims',
        name: '',
        address: '',
        phone: '',
        vehicleNumber: '',
        secondDriverName: '',
        secondDriverAddress: '',
        secondDriverPhone: '',
        secondDriverVehicleNumber: '',
        date: '',
        road: '',
        description: '',
    });

    const handleInput = async (event) => {

        event.persist();
        setReportMessage({ ...reportMessage, [event.target.name]: event.target.value })

    }

    const [error, setError] = useState({
        nameError: '',
        addressError: '',
        phoneError: '',
        vehicleNumberError: '',
        secondDriverNameError: '',
        secondDriverAddressError: '',
        secondDriverPhoneError: '',
        secondDriverVehicleNumberError: '',
        dateError: '',
        roadError: '',
        descriptionError: '',
    });

    const validate = async (e) => {

        let nameError = "";
        let addressError = "";
        let phoneError = "";
        let vehicleNumberError = "";
        let secondDriverNameError = "";
        let secondDriverAddressError = "";
        let secondDriverPhoneError = "";
        let secondDriverVehicleNumberError = "";
        let dateError = "";
        let roadError = "";
        let descriptionError = "";
        let isNotValid = false;


        if (reportMessage.name === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }
        if (reportMessage.address === "") {
            addressError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.vehicleNumber === "") {
            vehicleNumberError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.secondDriverName === "") {
            secondDriverNameError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.secondDriverAddress === "") {
            secondDriverAddressError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.secondDriverPhone === "") {
            secondDriverPhoneError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.secondDriverVehicleNumber === "") {
            secondDriverVehicleNumberError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.date === "") {
            dateError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.road === "") {
            roadError = "This field is required.";
            isNotValid = true;

        }
        if (reportMessage.description === "") {
            descriptionError = "This field is required.";
            isNotValid = true;

        }

        setError({
            ...error, nameError: nameError, addressError: addressError, phoneError: phoneError, vehicleNumberError: vehicleNumberError,
            secondDriverNameError: secondDriverNameError, secondDriverAddressError: secondDriverAddressError, secondDriverPhoneError: secondDriverPhoneError, secondDriverVehicleNumberError: secondDriverVehicleNumberError,
            dateError: dateError, roadError: roadError, descriptionError: descriptionError
        });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();

        let value = await validate();
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOhD8ZeaWrnn7',
            FormData: JSON.stringify(reportMessage),
            PageType: 'report claims'
        };
        //console.log(body);
        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))

        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(reportMessage).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        }
        else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }

    return (
        <Layout SEOTitle={"Report Claims | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={reportClaimsBanner} />

            <div className="contact-grid">
                <div className="uk-grid grid-container" uk-grid="true">

                    <div className="uk-width-1-2 contact-column half-width">
                        <div className="uk-card uk-card-default uk-card-body" id="contact-info">
                            <p>If you’ve had an accident, fill in the form on this page.  The details will be sent to the claims department.</p>
                            <p>If you need help, call <strong>0800 144 4488</strong> or email <a href="mailto: enquiry@freemotorlegal.co.uk" className="email">enquiry@freemotorlegal.co.uk</a></p>
                            <p>We are here to help!</p>
                            <p>If you’ve lost your Accident Survival Checklist or don’t have it with you, click here to download and view the pdf version.</p>
                        </div>
                    </div>

                    <div className="uk-width-1-2 half-width">
                        <div className="uk-card uk-card-default uk-card-body contact-second-column" id="contact-column-form">
                            <form className="uk-grid-small uk-grid" uk-grid="true"
                                onSubmit={handleSubmit}
                            >

                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.name} name="name" placeholder="Name" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.addressError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.address} name="address" placeholder="Address" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`} type="tel" id="form-stacked-text" onChange={handleInput} value={reportMessage.phone} name="phone" placeholder="Telephone" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.dateError !== "" ? 'red-border' : ''}`} type="text" onfocus="(this.type='date')" id="form-stacked-text" onChange={handleInput} value={reportMessage.date} name="date" placeholder="Date of accident" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.roadError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.road} name="road" placeholder="Road name where accident occured" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.vehicleNumberError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.vehicleNumber} name="vehicleNumber" placeholder="Your vehicle registration" />
                                </div>


                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.secondDriverVehicleNumberError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.secondDriverVehicleNumber} name="secondDriverVehicleNumber" placeholder="Other vehicle registration" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.secondDriverNameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.secondDriverName} name="secondDriverName" placeholder="Name of other driver" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.secondDriverAddressError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={reportMessage.secondDriverAddress} name="secondDriverAddress" placeholder="Address of other driver" />
                                </div>
                                <div className="uk-width-1-1@s margin-top">
                                    <input className={`uk-input ${error.secondDriverPhoneError !== "" ? 'red-border' : ''}`} type="tel" id="form-stacked-text" onChange={handleInput} value={reportMessage.secondDriverPhone} name="secondDriverPhone" placeholder="Telephone of other driver" />
                                </div>


                                <div className="uk-width-1-1@s margin-top">
                                    <textarea className={`uk-textarea ${error.descriptionError !== "" ? 'red-border' : ''}`} rows="6" onChange={handleInput} value={reportMessage.description} name="description" placeholder="Describe how the accident happened" />
                                </div>


                                <div className="uk-width-1-1@s">
                                    <div className="contact-send-btn">
                                        <Button
                                            id={"sign-up-submit"}
                                            btnClass={"primary"}
                                            btnType={"primary"}
                                            label={`${loading == false ? 'Submit' : 'Loading...'}`}
                                            isSubmit={"true"}
                                            additionalClass={"full-width-btn"}
                                        ></Button>
                                    </div>
                                </div>

                                {
                                    msgError ? <p className="error-message">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )

}

export default ReportClaims